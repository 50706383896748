import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
query FooterQuery {
    site {
        siteMetadata {
            footer_txt_1
            footer_txt_2
            footer_email
        }
    }
}
`

const Footer = () => {
    const { site } = useStaticQuery(query)
    const { footer_txt_1, footer_txt_2, footer_email } = site.siteMetadata
    const mailto = "mailto:" + footer_email

    return (
        <>
            <footer>
                <div className="footer-info">
                    <div className="container hidden-xs">
                        <div className="f-txt"> 
                            <div className="text-right">
                                <p style={{whiteSpace: 'pre'}}>{footer_txt_1}</p>
                                <p style={{whiteSpace: 'pre'}}>{footer_txt_2}</p>
                                <a className="mail-footer hvr-underline-from-left" href={mailto}>
                                    {footer_email}
                                </a>
                            </div>                    
                        </div>
                        <p className="copyright">&copy; 2017 - 2020 | S12 ARQUITETURA | TODOS OS DIREITOS RESERVADOS</p>
                    </div>
                    <div className="container visible-xs">
                        <div className="f-txt"> 
                            <div className="text-center">
                                <p>{footer_txt_1}</p>
                                <p>{footer_txt_2}</p>
                                <a className="mail-footer" href={mailto}>
                                    {footer_email}
                                </a>
                            </div>                    
                        </div>
                        <p className="copyright">&copy; 2017 - 2020 | S12 ARQUITETURA | TODOS OS DIREITOS RESERVADOS</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
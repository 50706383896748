import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import "../assets/scss/style.scss"

const query = graphql`
query LayoutQuery {
  site {
    siteMetadata {
      siteTitle: title
      siteUrl
    }
  }
}
`

const Layout = ({children}) => {

  const { site } = useStaticQuery(query)
  const { siteUrl } = site.siteMetadata

  return (
    <>
      <Header>
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2">
              <a href="/">
                <img src={siteUrl + "/assets/logo.svg"} alt="LOGO S12 ARQUITETURA" className="top-logo" />
              </a>
            </div>  
            <div className="pull-right visible-xs"> 
              <p>                                                    
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                  <span className="sr-only">Navegação</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>                           
              </p>
            </div>
            <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
              <div className="row">
                <nav id="bs-example-navbar-collapse-1" className="navbar collapse navbar-collapse pull-right">
                  <div className="pull-right">
                    <ul className="nav navbar-nav">
                      <li className="text-center">
                        <a className="link-menu primeiro" href="/quem-somos">S12 ARQUITETURA</a>
                      </li>
                      <li className="text-center">
                        <a className="link-menu" href="http://obras.s12arquitetura.com.br/">CALCULADORA DE OBRA</a>
                      </li>
                      <li className="text-center dropdown">
                        <button 
                          onClick={e => e.preventDefault()}
                          className="btn btn-link link-menu link-portfolio" 
                          data-target="#" 
                          data-toggle="dropdown"
                        >
                          PORTFÓLIO <span className="caret"></span>
                        </button>
                        <ul 
                          className="dropdown-menu multi-level" 
                          aria-labelledby="dropdownMenu"
                        >
                          <li className="text-center dropdown-submenu">
                            <a href="/portfolio-residencial">Residencial</a>
                          </li>
                          <li className="text-center dropdown-submenu">
                            <a href="/portfolio-comercial">Comercial</a>
                          </li>
                        </ul>
                      </li>
                      <li className="text-center">
                        <a className="link-menu" href="/contato">CONTATO</a>
                      </li>
                      <li className="text-center">
                        <a className="link-icones" target="_blank" href="https://www.facebook.com/s12arquitetura" rel="noreferrer">
                          <i className="fa fa-facebook"></i>
                        </a>
                        <a className="link-icones" target="_blank" href="https://www.instagram.com/s12arquitetura/" rel="noreferrer">
                          <i className="fa fa-instagram"></i>
                        </a>
                        <a className="link-icones ultimo" href="mailto:contato@s12arquitetura.com.br">
                          <i className="fa fa-envelope-o"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Header>

      <main>
        {children}
      </main>
      
      <Footer/>
      
      <Helmet>
        
      </Helmet>
    </>
  )
}

export default Layout

